import React from "react";

import "./index.css";

const InfoProfile = () => {
  return (
    <div className="main-profile-head mt-5">
      <div className="business-prof-setup-head">
        Customize Your Business Profile
      </div>
      <div className="business-prof-descrp">
        A Few Small tweaks can help your business stand out and get found
      </div>

      <div>
        Your Should Continue this my following steps
      </div>

      <div>
        
      </div>
    </div>
  );
};

export default InfoProfile;
