import React from "react";

const ContactMap =()=>{
    return(
        <div>
            <h2 className="mt-3"style={{fontSize:"44px", fontFamily:"Figtree", fontWeight:"600"}}>
                Get In Touch
            </h2>

            <p style={{fontSize:"24px", fontFamily:"Figtree", fontWeight:"400"}}>
              Lorem ipsum dolor sit amet consectetur. Mi ipsum diam 
            </p>
        </div>
    )
}

export default ContactMap